<template>
    <div id="AdminSystem"><router-view></router-view></div>
</template>
<script>
export default {
    name: 'AdminSystem',
}
</script>
<script setup></script>
<style lang="scss" scoped></style>
